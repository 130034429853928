<template>
  <div class="contactWrapper">
    <h1>Contact</h1>
    <br />
    <div class="contactBlock">
      <table>
        <tr>
          <td class="contacttype">email</td>
          <td>
            <a
              href="mailto: macrowe777@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="mdiEmailMarkAsUnread" />
            </a>
          </td>
        </tr>
        <tr>
          <td class="contacttype">twitter</td>
          <td>
            <a
              href="https://https://twitter.com/BIMCrowe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="mdiTwitter" />
            </a>
          </td>
        </tr>
        <tr>
          <td class="contacttype">linkedin</td>
          <td>
            <a
              href="https://www.linkedin.com/in/crowemark/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="mdiLinkedin" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <br />
    <br />
    <div class="affiliateBlock">
      <h3>Affiliation</h3>
      <table>
        <tr>
          <td class="contacttype">
            <p class="companyname companycard fw-bold">BIM Academy</p>
            <p class="role companycard fst-italic">Associate</p>
            <p class="date companycard fst-italic fw-light">2013 - present</p>
          </td>
          <td>
            <a
              href="https://www.linkedin.com/in/test/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img class="logo img-fluid mx-auto d-block" :src="bima.url" />
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import mdiLinkedin from '@/assets/material-design-icons/linkedin.svg'
import mdiTwitter from '@/assets/material-design-icons/twitter.svg'
import mdiEmailMarkAsUnread from '@/assets/material-design-icons/email-mark-as-unread.svg'

export default {
  name: 'Contact',
  data: () => ({
    bima: {
      url:
        'https://www.bimacademy.global/wp-content/uploads/2020/04/BIM-Academy-Social-Media-Icon-grey-50x50.png',
    },
  }),
  setup() {
    return {
      mdiLinkedin,
      mdiTwitter,
      mdiEmailMarkAsUnread,
    }
  },
}
</script>
<style scoped>
.contacttype {
  width: 10em;
}
.companycard {
  margin: 0px;
}

.contactWrapper {
  margin-top: 20vh;
}
</style>
